import React from 'react';
import { MainLayout } from '../../layouts'
import {Footer, HeroSection, LineDotContainer, Paragraph, Title} from "../../components";


import  styles from './styles.module.css'

export const TermsAndConditions = () => {
    return (
        <MainLayout>
            <HeroSection className={styles.customHero }>
                <Title colour="white" weight="bold" size="large">
                    Terms and Conditions for Website Usage
                </Title>
            </HeroSection>
            <div className={ styles.innerContent}>


                <LineDotContainer >
                    <Title>Terms and Conditions for Website Usage</Title>
                    Welcome to our website.
                    If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which govern Calibrate Consulting’s (“Calibrate”) relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.
                    The term ‘Calibrate’, ‘us’ or ‘we’ refers to the owner of the website, Calibrate Consulting (trading name for Product Control Limited), a company incorporated in England and Wales, under registration number 06970258, whose registered office is 14 Park View, Pinner, HA5 4LN.
                    The term ‘you’ refers to the user or viewer of our website.
                    <Paragraph>The use of this website is subject to the following terms of use:</Paragraph>
                    <ul>
                        <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                        <li>Every effort is made to keep the website up and running smoothly. However, Calibrate takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</li>
                        <li>When you visit this website a cookie may be downloaded onto your computer. This enables us to provide you with a better website by keeping track of the areas of the website you visit, for example your areas of specific interest. A cookie may allow Calibrate, its service providers and other third parties to recognise repeat visits and recall information which you have previously supplied to Calibrate, such as your name. You may adjust how your computer deals with cookies using your internet browser. If you do allow cookies to be used, personal information may be stored by us for use by third parties.</li>
                        <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                        <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
                        <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                        <li>Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.</li>
                        <li>Through this website you are able to link to other websites which are not under the control of Calibrate. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them. We have no responsibility for the content of the linked website(s).</li>
                        <li>In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</li>
                        <li>Calibrate is committed to a policy of equal opportunity of employment.</li>
                    </ul>


                    Your use of this website and any dispute arising out of such use of the website is subject to the laws of England.
                    Copyright notice
                    This website and its content is copyright of Calibrate Consulting © Calibrate 2016. All rights reserved.
                    Any redistribution or reproduction of part or all of the contents in any form is prohibited other than the following:
                    <ul>
                        <li>you may print or download to a local hard disk extracts for your personal and non-commercial use only</li>
                        <li>you may copy the content to individual third parties for their personal use, but only if you acknowledge the website as the source of the material</li>
                        <li>you may not, except with our express written permission, distribute or commercially exploit the content. Nor may you transmit it or store it in any other website or other form of electronic retrieval system.</li>
                    </ul>

                </LineDotContainer>

            </div>
            <Footer/>
        </MainLayout>
    )
}